import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'development'
        ? 'http://news-sticker.isn-speed.com/api/'
        : 'http://news-sticker.isn-speed.com/api/'
    // baseURL: 'http://192.168.137.214:3000/api/',
})

instance.interceptors.request.use(
    (req) => {
        const token = localStorage.getItem('access_token')
        req.headers.Authorization = token ? 'Bearer ' + token : ''
        return req
    },
    undefined
)

instance.interceptors.response.use(
    undefined,
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('access_token')
            localStorage.removeItem('user')
            window.location.reload()
        }
    }
)

export default instance