<template>
  <v-app>
  <app-bar/>
    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from './components/appBar.vue';

export default {
  name: 'App',
  components:{ AppBar},
  data: () => ({
   
  }),
  methods:{
    
  },
 
};
</script>
