<template>
  <v-row>
    <v-col cols="4" offset="4">
      <v-form @submit.prevent="login">
        <v-card>
          <v-card-title >
            <div class="">
              Login
            </div>
          </v-card-title>
          <v-card-text>
            <v-row class="mt-1">
              <v-col cols="12">
                <v-text-field outlined label="Username" dense v-model="form.username"/>
                <v-text-field outlined label="Password" type="password" v-model="form.password" dense/>
              </v-col>
            </v-row>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions class="justify-end px-3">
            <v-btn type="submit" tile outlined color="primary darken-5" :loading="btnLoading">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
    <v-snackbar
      v-model="snackbar"
      color="error"
      top
      timeout="2000"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
  
</template>
<script>
import API from '@/store/api'
import { mapMutations } from 'vuex'

export default {
  data: () => ({
    form: {
      username: '',
      password: '',
    },
    btnLoading: false,
    snackbar: false,
    message: '',
  }),
  computed: {
  },
  methods:{
    ...mapMutations(['SET_LOGGEDIN']),
    async login() {
        this.btnLoading = true
        let FD = new FormData()
        FD.append('username', this.form.username)
        FD.append('password', this.form.password)
        let post = await API.post('/login.php', FD)
         if (post.data.success) {
          this.btnLoading = false
          this.SET_LOGGEDIN(true)
          localStorage.setItem('user',JSON.stringify(post.data.data))
          localStorage.setItem('access_token', post.data.token)
          this.$router.push('/')
        } else {
          this.btnLoading = false
          this.message = post.data.message
          this.snackbar = true
        }
      }
  }
  
}
</script>