<template>
  <div>
    <div>
      <v-row class="mb-2">
        <v-col cols="6" >
          <v-form ref="form" @submit.prevent="submit">
              <v-row class="">
                <v-col cols="12">
                  <!-- <v-text-field
                    v-model="form.topik"
                    :rules="[rules.required, rules.counter]"
                    label="Topic"
                    outlined
                    dense
                    counter
                    maxlength="20"
                  ></v-text-field>
                   <v-textarea
                    v-model="form.isi"
                    :rules="[rules.required, rules.counter2]"
                    label="News"
                    outlined
                    dense
                    counter
                    maxlength="200"
                    rows="3"
                  ></v-textarea> -->
                  <v-text-field
                    v-model="form.topik"
                    label="Topic"
                    outlined
                    dense
                    counter
                    maxlength="20"
                  ></v-text-field>
                   <v-textarea
                    v-model="form.isi"
                    label="News"
                    outlined
                    dense
                    counter
                    maxlength="200"
                    rows="3"
                  ></v-textarea>
                </v-col>
              </v-row>
            <v-spacer></v-spacer>
            <v-card-actions class="justify-start">
              <v-btn small type="submit" tile outlined color="primary" :loading="loadingBtn">Create</v-btn>
            </v-card-actions>
          </v-form>
        </v-col>
      </v-row>
    </div>
    <v-card class="pt-5 px-2">
      <v-row no-gutters>
        <v-col cols="4">
          <v-text-field
            v-model="filter.isi"
            label="News Filter"
            outlined
            dense
            persistent-hint
            @keypress.enter="filterUp()"
            hint="You can press enter to filter."
            
          >
          </v-text-field>

        </v-col>
        <v-col cols="1">
          <v-btn icon outlined tile color="primary" @click="filterUp()" height="40px" width="40px">
            <v-icon>
              mdi-filter
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loadingTable"
        class="elevation-1"
        dense
        disable-sort
        :page.sync="pageSync"
        :options.sync="options"
        :items-per-page.sync="itemPerPage"
        :server-items-length="itemsTotal"
      >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-bind="attrs" v-on="on" @click="Confirm(item)">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Hapus Data</span>
        </v-tooltip>
      </template>
      </v-data-table>
    </v-card>
     <v-snackbar
      v-model="snackbar"
      :color="color"
      top
      timeout="2000"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <confirm v-model="dialogConfirm" @tidak="dialogConfirm = false" @ya="deleteNews"/>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
import Confirm from '../components/confirm.vue'

  export default {
    name: 'Home',
    components: { Confirm },
    data: () => ({
      pageSync: 1,
      options: null,
      itemPerPage: 10,
      headers: [
          { text: 'No', value: 'no', width: '50px'},
          { text: 'Topic', value: 'judul', width: '300px'},
          { text: 'News', value: 'isi' },
          { text: 'TIme', value: 'waktu' },
          { text: 'Action', value: 'action', width: '50px'},
        ],
      rules: {
      // required: value => !!value || 'Required.',
      // counter: value => !value || value && value.length <= 20 || 'Max 20 characters',
      // counter2: value => value && value.length <= 200 || 'Max 200 characters',
      },
      color: '',
      message: '',
      snackbar: false,
      dialogConfirm: false,
      loadingTable: false,
      loadingBtn: false,
      form:{
        topik: '',
        isi: '',
      },
      filter:{
        isi: ''
      },
      deletedNews: ''
    }),
    watch:{
      options: {
        async handler(){
          this.getItems()
        },
        deep: true
      },
    },
    computed: {
      ...mapState('news', [
        'items','itemsTotal'
      ])
    },
    methods: {
      ...mapActions('news', [
        'GET_ITEMS','SUBMIT_NEWS','DELETE_NEWS'
      ]),
      filterUp(){
        this.getItems()
      },
      async getItems(){
        if (this.filter.isi) {
          const { page, itemsPerPage: perPage } = this.options
          let payload = { page, perPage, ...this.filter }
          await this.GET_ITEMS(payload)
          this.$refs.form.reset()

        } else {
          const { page, itemsPerPage: perPage } = this.options
          let payload = { page, perPage }
          await this.GET_ITEMS(payload)
          this.$refs.form.reset()
        }
        
      },
      Confirm(item){
        this.deletedNews = item.id_news
        this.dialogConfirm = true
      },
      async deleteNews(){
        let FD = new FormData()
        FD.append('id_news', this.deletedNews)
        try {
          let res = await this.DELETE_NEWS(FD)
          if (res.data.success) {
            this.message = res.data.message
            this.snackbar = true
            this.color = 'primary'
            this.getItems()
            this.dialogConfirm = false
          } else {
            this.dialogConfirm = false
            this.message = res.data.message
            this.snackbar = true
            this.color = 'error'
          }
        } catch (error) {
          this.loadingBtn = false
          this.message = 'Something went wrong.'
          this.snackbar = true
          this.color = 'error'
        }
      },
      //  refresh(){
      //   this.$refs.form.reset()
      //   this.loadingTable = true
      //   try {
      //     let res =  this.getItems()
      //     if (res.data.success) {
      //       this.loadingTable = false
      //       console.log(this.items);
      //     }
      //   } catch (error) {
      //     this.loadingTable = false
      //     this.message = 'Something went wrong.'
      //     this.snackbar = true
      //     this.color = 'error'
      //   }
      // },
      async submit(){
        let FD = new FormData()
        FD.append('topik', this.form.topik)
        FD.append('isi', this.form.isi)
        this.loadingBtn = true
        try {
          let res = await this.SUBMIT_NEWS(FD)
          if (res.data.success) {
            this.loadingBtn = false
            this.message = res.data.message
            this.snackbar = true
            this.color = 'primary'
            this.getItems()
          } else {
            this.loadingBtn = false
            this.message = res.data.message
            this.snackbar = true
            this.color = 'error'
          }
        } catch (error) {
          this.loadingBtn = false
          this.message = 'Something went wrong.'
          this.snackbar = true
          this.color = 'error'
        }
      }
    },
  }
</script>
