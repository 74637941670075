import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      auth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{
      auth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  const tokenExist = localStorage['access_token']
  const userData = localStorage['user']
  if (to.path == '/login' && tokenExist && userData)
    next('/')

  if(to.meta.auth == false)
    return next()

  if (tokenExist && userData)
    next()
  else
    next('/login')
})

export default router
