import Api from './api'

const state = () => ({
    items: [],
    itemsTotal: null
})

const mutations = {
    SET_ITEMS(state, value) {
        state.items = value
    },
    SET_ITEMS_TOTAL(state, value) {
        state.itemsTotal = value
    },
}

const actions = {
    async GET_ITEMS({
        commit
    }, payload) {
        const res = await Api.get(`/data/read.php`, { params: payload })
        commit('SET_ITEMS', res.data.data)
        commit('SET_ITEMS_TOTAL', parseInt(res.data.total))
        return Promise.resolve(res)
    },
    async SUBMIT_NEWS (ctx, body) {
    const res = await Api.post('/data/add.php', body)
    return Promise.resolve(res)
    },
    async DELETE_NEWS (ctx, body) {
    const res = await Api.post('/data/delete.php', body)
    return Promise.resolve(res)
    },
}

export default {
    state,
    mutations,
    actions
}