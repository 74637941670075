<template>
  <v-app-bar
      app
      color="primary"
      dark
    >
    <h3>NEWS STICKER</h3>
      <v-spacer></v-spacer>

      <v-btn text @click="LOGOUT"  v-if="loggedIn == true" >
        <span class="mr-2">Logout</span>
        <v-icon >mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({
    isLoggedIn: false
  }),
  computed: {
    ...mapState(['loggedIn'])
  },
  methods:{
    ...mapActions([
      'GET_LOGGED_IN',
      'LOGOUT'
    ]),
  },
  mounted() {
    this.GET_LOGGED_IN()
    console.log(this.loggedIn);
  },
  watch: {
    loggedIn() {
      console.log(this.loggedIn)
    }
  }
}
</script>

<style>

</style>