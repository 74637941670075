<template>
  <v-dialog
    width="400"
    persistent
    v-model="value"
    >
    <v-card>
      <v-card-title >
        <v-row>
          <v-col cols="8" offset="2">
            <p class="display-1" >Are you sure ?</p>
          </v-col>
        </v-row>
      </v-card-title>
      <v-spacer></v-spacer>
      <v-card-actions class="justify-center">
        <v-btn @click="tidak" tile text color="error darken-1">No</v-btn>
        <v-btn @click="ya" tile outlined color="primary darken-5">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props:['value',],
  data : () => ({
  }),
  methods:{
    ya(){
      this.$emit('ya' )
    },
    tidak(){
      this.$emit('tidak')
    },
  },
  // watch: {
  //   value(val){
  //     if (this.deleteConfirmText) {
        
  //     }
  //   }
  // }
}

</script>