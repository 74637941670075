import Vue from 'vue'
import Vuex from 'vuex'
import news from "./news";
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false
  },
  mutations: {
    SET_LOGGEDIN(state, value) {
        state.loggedIn = value
    },
  },
  actions: {
    GET_LOGGED_IN({ commit }){
      const user = JSON.parse(localStorage.getItem('user'))
      if (user) {
        commit('SET_LOGGEDIN', user.UserID != '' && user.User != '' && user.GroupID != '' && user.Group != '')
      }
    },
    LOGOUT({ commit }) {
      localStorage.removeItem('user')
      localStorage.removeItem('access_token')
      router.push('/login')
      commit('SET_LOGGEDIN', false)
    }
  },
  modules: {
    news: {
      namespaced: true,
      ...news
    },
  }
})
